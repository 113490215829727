<template>
  <div>
    <div v-if="error" style="color: white; background: red;">
      <pre> {{ error }} </pre>
    </div>
    <div v-else-if="false" :style="{background: connected ? 'green' : 'grey', color: connected ? 'white' : 'black'}">
      <pre> {{ this.server }} -- Listening: {{ listenRoom }} -- Connected: {{ connected }}</pre>
    </div>
    <!--
    <div v-for="[key, feed] of feedsArray" :key="key" class="container">
      <template v-if="feed">
        <pre>{{ key }}</pre>
        <div>
          <video
            :ref="'video_'+key"
            autoplay
            playsinline
            muted
          />
        </div>
      </template>
    </div>
    -->
    <div class="ma-0 pa-0">
      <Plyr :options="plyr">
        <video ref="primaryVideo" controls autoplay playsinline muted />
      </Plyr>
    </div>
  </div>
</template>

<script>
import Janus from '@/lib/janus'
import { sleep } from '@/lib/helpers'
import Plyr from './Plyr'

export default {
  name: 'JanusViewer',
  components: { Plyr },
  props: {
    server: {
      type: String,
      default: 'wss://janus.apps.zenger.tv/janus'
    },
    listenRoom: {
      type: Number,
      default: 1234,
    },
    searchName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      error: null,
      opaqueId: 'JanusClickerViewer',
      plugin: 'janus.plugin.videoroom',
      janus: null,
      manager: null,
      managerId: null,
      managerPrivId: null,
      feeds: {},
      connected: false,
      primaryFeed: null,
      plyr: {
        controls: ['play', 'play-large', 'mute', 'volume'],
        autoplay: true,
        clickToPlay: false,
        keyboard: { focused: false, global: false }
      }
    }
  },
  computed: {
    feedsArray () {
      return Object.entries(this.feeds)
    }
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      Janus.init({
        debug: 'all',
        callback: () => {
          this.janus = new Janus({
            server: this.server,
            iceServers: [
              {
                urls: ['stun:stun.l.google.com:19302']
              },
              {
                urls: ['stun:apps.zenger.tv:3478']
              },
              {
                urls: ['turn:apps.zenger.tv:3478'],
                username: 'guest',
                credential: 'zenger'
              }
            ],
            success: () => {
              this.janus.attach({
                plugin: this.plugin,
                opaqueId: this.opaqueId,
                success: (pluginHandle) => {
                  this.manager = pluginHandle
                  console.log('::manager:: Plugin attached! (' + this.manager.getPlugin() + ', id=' + this.manager.getId() + ')')
                  const message = {
                    request: 'join',
                    room: this.listenRoom,
                    ptype: 'publisher',
                    display: this.opaqueId
                  }
                  this.manager.send({ message })
                },
                error: (err) => { console.error(err) },
                onmessage: (msg) => {
                  console.log('::manager:: Got MSG:', msg)
                  const event = msg.videoroom
                  if (event && msg.room === this.listenRoom) {
                    if (event === 'joined') {
                      this.connected = true
                      this.managerId = msg.id
                      this.managerPrivId = msg.private_id
                      if (msg.publishers) {
                        for (const f in msg.publishers) {
                          this.newRemoteFeed(msg.publishers[f])
                        }
                      }
                    } else if (event === 'event') {
                      if (msg.publishers) {
                        for (const f in msg.publishers) {
                          this.newRemoteFeed(msg.publishers[f])
                        }
                      }
                      if (msg.unpublished) {
                        this.removeRemoteFeed(msg.unpublished)
                      }
                      if (msg.leaving) {
                        this.removeRemoteFeed(msg.leaving)
                      }
                    }
                  }
                }
              })
            },
            error: (err) => {
              console.log('1st level err:', err)
              if (err.includes('Error connecting')) {
                return this.retry()
              }
              if (err.includes('Lost connection')) {
                return this.retry()
              }
            }
          })
        }
      })
    },
    async retry () {
      await sleep(3000)
      window.location.reload()
    },
    newRemoteFeed (feed) {
      console.log('new remote feed:', feed)
      this.$set(this.feeds, feed.id, feed)
      if (feed.display === this.searchName) {
        this.primaryFeed = feed
        this.janus.attach({
          plugin: this.plugin,
          opaqueId: this.opaqueId,
          success: (pluginHandle) => {
            feed.subscriber = pluginHandle
            console.log(`::subscriber:: (id:${feed.id}) Plugin attached! (${feed.subscriber.getPlugin()}, id=${feed.subscriber.getId()})`)
            const message = {
              request: 'join',
              room: this.listenRoom,
              ptype: 'subscriber',
              feed: feed.id,
              private_id: this.managerPrivId
            }
            feed.subscriber.send({ message })
          },
          error: (err) => { console.error(err) },
          onmessage: (msg, jsep) => {
            console.log(`::subscriber:: (id:${feed.id}) Got MSG:`, msg)
            if (jsep) {
              feed.subscriber.createAnswer({
                jsep,
                media: { audioSend: false, videoSend: false },
                success: (jsep) => {
                  const message = { request: 'start', room: this.listenRoom }
                  feed.subscriber.send({ message, jsep })
                },
                error: (err) => { console.error(err) }
              })
            }
          },
          onremotestream: (stream) => {
            console.log(`::subscriber:: (id:${feed.id}) Got Stream:`, stream)
            feed.stream = stream
            // Janus.attachMediaStream(this.$refs['video_' + feed.id][0], stream)
            Janus.attachMediaStream(this.$refs.primaryVideo, stream)
          }
        })
      }
    },
    removeRemoteFeed (feedId) {
      console.log('remove feed', feedId)
      const feed = this.feeds[feedId]
      if (feed) {
        if (feed.subscriber) {
          feed.subscriber.detach()
        }
        if (feed.republisher) {
          feed.republisher.detach()
        }
        this.$set(this.feeds, feedId)
      }
      if (feed === this.primaryFeed.id) {
        this.primaryFeed = null
      }
    }
  }
}
</script>

<style scoped>
  video {
    position: inline-block;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }

  @media only screen and (min-width: 580px) {
    video {
      height: calc(100vh - 128px);
    }
  }

  video::-webkit-media-controls-timeline {
    display: none;
  }
</style>
