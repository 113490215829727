<template>
  <v-app>
    <v-app-bar app>
      <v-btn icon to="/">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title v-if="device">{{ device.name }}</v-toolbar-title>
      <v-toolbar-title v-else>Loading...</v-toolbar-title>
      <v-spacer />
      <div v-if="device && device.isOnline" class="items-center">
        <v-btn small v-if="$route.query.noVideo" :to="`/${$route.params.deviceId}`">
          Show Screen
        </v-btn>
        <v-btn small v-else :to="`/${$route.params.deviceId}?noVideo=true`">
          Hide Screen
        </v-btn>
      </div>
      <ProfileAppBar />
    </v-app-bar>
    <v-main>
      <div v-if="device && device.isOnline && !$route.query.noVideo" class="screen-container">
        <div class="screen-inside">
          <JanusViewer :searchName="device.name" :key="device.name" />
        </div>
      </div>
      <v-row v-if="device && device.isOnline && canManage" class="control-plane">
          <div class="flex-grow-1 flex-shrink-1 d-flex justify-start control-col-fill"></div>
          <div class="flex-grow-1 flex-shrink-1 d-flex control-col control-pn">
            <v-btn large depressed class="mr-2" @click="prev()">
              <v-icon large left>mdi-chevron-left</v-icon>
              Previous
            </v-btn>
            <v-btn large depressed @click="next()">
              Next
              <v-icon large right>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <div class="flex-grow-1 flex-shrink-1 d-flex control-col control-goto">
            <v-text-field type="number" v-model="page" style="max-width: 300px; height: 40px;" outlined dense label="Page Number">
              <template v-slot:append-outer>
                <v-btn text class="mt-n2" @click="goto(page)">
                  Go To Page
                  <v-icon right>mdi-page-next-outline</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>
        </v-row>
      <v-container>
        <v-row v-if="!device || !device.isOnline">
          <v-col cols="12">
            <v-alert icon="mdi-monitor-off">Device is not available now.</v-alert>
          </v-col>
        </v-row>
        <v-row v-if="device && device.isOnline && !canManage">
          <v-col cols="12">
            <v-alert dense icon="mdi-monitor-off">Permissions are required for managing the slideshow.</v-alert>
          </v-col>
        </v-row>
        <v-row v-if="$store.state.auth.user.role === 'admin'">
          <v-col cols="12">
            <v-toolbar color="transparent" flat>
              <v-toolbar-title>Permissions</v-toolbar-title>
              <v-spacer />
              <v-switch v-model="showOffline" label="Show Offline Users" />
            </v-toolbar>
            <v-simple-table dark>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Name
                    </th>
                    <th class="text-center">
                      Can View
                    </th>
                    <th class="text-center">
                      Can Manage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in filteredUsers"
                    :key="item._id"
                  >
                    <td>
                      {{ item.name || item.email }}
                      <v-chip class="ml-4" label v-if="item.role === 'admin'" small>admin</v-chip>
                    </td>
                    <td class="text-center">
                      <v-simple-checkbox :value="canUserView(item)" :disabled="item.role === 'admin'" @input="changeView(item)"></v-simple-checkbox>
                    </td>
                    <td class="text-center">
                      <v-simple-checkbox :value="canUserManage(item)" :disabled="item.role === 'admin'" @input="changeManage(item)"></v-simple-checkbox>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
  .screen-container {
    width: 100%;
    padding-top: 56.64%;
    background: #000000;
    position: relative;
  }

  .screen-inside {
    position: absolute;
    inset: 0;
  }

  .control-plane {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 128px;
    align-items: center;
    flex-wrap: wrap;
  }

  .control-col {
    width: 100%;
  }

  .control-pn {
    justify-content: center;
  }

  .control-goto {
    justify-content: center;
  }

  .control-col-fill {
    width: 0%;
    display: hidden;
  }

  @media only screen and (min-width: 580px) {

    .screen-container {
      height: calc(100vh - 128px);
      padding-top: 0;
    }

    .screen-inside {
      position: relative;
      inset: initial;
    }

    .control-plane {
      flex-direction: row;
      height: 64px;
    }

    .control-col {
        width: 50%;
    }

    .control-pn {
      justify-content: start;
      padding: 0 24px;
    }

    .control-goto {
      justify-content: end;
    }

  }

  @media only screen and (min-width: 800px) {
    .control-col {
      width: 33.33%;
    }
    .control-col-fill {
      width: 33.33%;
      display: flex;
    }
    .control-pn {
      justify-content: center;
    }
  }
</style>

<script>
import JanusViewer from '../components/JanusViewer'
import ProfileAppBar from '../components/ProfileAppBar.vue'
import { keyBy } from 'lodash'

export default {
  name: 'DeviceControl',
  layout: 'empty',
  components: { JanusViewer, ProfileAppBar },
  data () {
    return {
      page: '',
      device: null,
      users: {},
      showOffline: false,
    }
  },
  sockets: {
    device (data) {
      if (data._id === this.$route.params.deviceId) {
        this.device = data
        if (!this.canUserView(this.$store.state.auth.user)) {
          this.$router.push('/')
        }
      }
    },
    users (data) {
      this.users = keyBy(data, '_id')
    },
    user (data) {
      this.$set(this.users, data._id, data)
    }
  },
  created () {
    this.$socket.client.emit('getDevice', this.$route.params.deviceId)
    this.$socket.client.emit('getUsers')
    document.addEventListener('keydown', this.keyPress)
  },
  computed: {
    canManage () {
      return this.$store.state.auth.user.role === 'admin' || this.device.managedBy.includes(this.$store.state.auth.user._id)
    },
    filteredUsers () {
      let users = Object.values(this.users)
      if (!this.showOffline) {
        users = users.filter(i => !!i.isOnline)
      }
      return users
    }
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.keyPress)
  },
  methods: {
    changeView(user) {
      console.log('change view', user)
      if (this.canUserView(user)) {
        this.$socket.client.emit('removeViewPermission', this.device._id, user._id)
      } else {
        this.$socket.client.emit('addViewPermission', this.device._id, user._id)
      }
    },
    changeManage(user) {
      console.log('change manage', user)
      if (this.canUserManage(user)) {
        this.$socket.client.emit('removeManagePermission', this.device._id, user._id)
      } else {
        this.$socket.client.emit('addManagePermission', this.device._id, user._id)
      }
    },
    canUserView(user) {
      if (user.role === 'admin') { return true }
      return this.device.visibleTo.includes(user._id)
    },
    canUserManage(user) {
      if (user.role === 'admin') { return true }
      return this.device.managedBy.includes(user._id)
    },
    prev() {
      if (this.canManage) {
        console.log('PREV')
        this.$socket.client.emit('sendCmd', { cmd: 'PREV', to: this.$route.params.deviceId })
      }
    },
    next() {
      if (this.canManage) {
        console.log('NEXT')
        this.$socket.client.emit('sendCmd', { cmd: 'NEXT', to: this.$route.params.deviceId })
      }
    },
    goto(page) {
      if (this.canManage) {
        console.log('GOTO', page)
        this.$socket.client.emit('sendCmd', { cmd: 'GOTO', page, to: this.$route.params.deviceId })
      }
    },
    keyPress (ev) {
      if (ev.code === 'ArrowLeft') {
        this.prev()
      }
      if (ev.code === 'ArrowRight') {
        this.next()
      }
    }
  }
}
</script>
