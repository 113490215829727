<template>
  <div>
    <slot />
  </div>
</template>

<script>
import Plyr from 'plyr'

export default {
  name: 'Plyr',
  props: {
    /** Options object for plyr config. **/
    options: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    source: {
      type: Object,
      required: false,
      default: null,
    },
    /** Array of events to emit from the plyr object **/
    emit: {
      type: Array,
      required: false,
      default () { return [] }
    }
  },
  data () {
    return {
      player: {}
    }
  },
  computed: {
    opts () {
      const options = this.options
      // eslint-disable-next-line no-prototype-builtins
      if (!this.options.hasOwnProperty('hideYouTubeDOMError')) {
        options.hideYouTubeDOMError = true
      }
      return options
    }
  },
  watch: {
    source (val) {
      if (val) {
        this.player.source = val
      }
    }
  },
  mounted () {
    this.player = new Plyr(this.$el.firstChild, this.opts)
    if (this.source) {
      this.player.source = this.source
    }
    this.emit.forEach(element => {
      this.player.on(element, this.emitPlayerEvent)
    })
  },
  beforeDestroy () {
    try {
      this.player.destroy()
    } catch (e) {
      if (!(this.opts.hideYouTubeDOMError && e.message === 'The YouTube player is not attached to the DOM.')) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  },
  methods: {
    emitPlayerEvent (event) {
      this.$emit(event.type, event)
    }
  }
}
</script>

<style src="plyr/dist/plyr.css"></style>

<style>
:root {
  --plyr-control-icon-size: 24px;
  --plyr-control-spacing: 12px;
  --plyr-color-main: #CC0000;
}
</style>
